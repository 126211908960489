.container {
    width: 20em;
    position: fixed;
    z-index: 2000;
    right: 10px;
    background: rgb(32, 64, 209);
    color: rgb(255, 255, 255);
    visibility: hidden;
    padding: 1em 1.5em;
    border-radius: 0.25em;
    overflow: hidden;
    transition: all 300ms;
    box-shadow: 0 0 15px gainsboro;
  }

  .container.active {
    visibility: visible;
    transform: translateY(10px);
  }

  .icon {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    width: 20px;
    margin: 0;
    cursor: pointer;
    transition: transform 100ms;
    filter: invert(1);
  }

  .icon:hover {
    transform: scale(1.1);
  }

  .message {
    width: 100%;
    margin-top: 1em;
    margin-bottom: 0.5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.25em;
  }

  .action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .action img {
    filter: invert(1);
    margin-left: 4px;
    width: 1.3em;
  }

  .copy {
    cursor: pointer;
    font-size: 1.5rem;
    padding: 0.2em 1em;
    border-radius: 0.25em;
    background: var(--card-bg1);
    color: white;
    outline: 2px solid transparent;
  }

  .copy:hover {
    background: var(--card-bg3);
    outline: 2px solid var(--card-bg1);
  }