.Home {
  width: 100%;
  position: relative;

  &__loading {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .HeroSection {
    background: url("../../../public/img/bg-moon.png"), var(--navbar-bg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    &__inner {
      max-width: 1200px;
      min-height: 90vh;
      margin: 0 auto;
      padding: 64px 24px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__image {
      width: 280px;
      height: 250px;
      margin-bottom: 32px;

      @include respond(tablet-lg) {
        width: 360px;
        height: auto;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__content {
      text-align: center;
    }

    &__title {
      margin-bottom: 16px;
    }
  }

  .CountDownSection {
    width: 100%;
    background: url("../../../src/assets/bg-clouds.jpg");
    background-size: cover;
    background-repeat: no-repeat;

    &__inner {
      max-width: 1200px;
      margin: 0 auto;
      padding: 64px 24px 0 24px;
      min-height: 70vh;

      display: flex;
      flex-direction: column;
      align-items: center;

      @include respond(tablet-sm) {
        flex-direction: row;
        min-height: 50vh;
      }

      @include respond(desktop) {
        min-height: 70vh;
      }
    }

    &__timer {
      flex: 0 0 100%;
      background: var(--pink);
      color: var(--white);
      min-height: 300px;

      @include respond(tablet-sm) {
        flex: 0 0 45%;
        min-height: 190px;
      }

      @include respond(desktop) {
        min-height: 400px;
      }

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 32px;
      border-radius: 8px;

      & h2 {
        margin-bottom: 32px;
        max-width: 200px;
        
        @include respond(desktop) {
          font-size: 48px;
          max-width: 290px;
        }
      }

      & p {
        margin-bottom: 32px;
      }

      & a {
        & img {
          margin-right: 8px;
          width: 32px;
        }

        & button {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          @include respond(desktop) {
            width: auto;
            font-size: 20px;
          }
        }
      }
    }

    &__image {
      flex: 0 0 50%;
      align-self: flex-end;
      margin-bottom: -8px;
      

      @include respond(tablet-sm) {
        flex: 0 0 50%;
        // width: 500px;
        margin: 0 auto -8px auto;
        height: auto;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .AboutSection {
    width: 100%;

    &__inner {
      max-width: 1200px;
      padding: 64px 24px 0px 24px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      @include respond(desktop) {
        flex-direction: row;
      }
    }

    &__content {
      flex: 0 0 50%;
      text-align: center;

      @include respond(desktop) {
        text-align: left;
      }

      & h2 {
        margin-bottom: 24px;
      }

      & span {
        color: var(--green);
      }
    }

    & a {
      display: flex;
      width: 200px;
      align-items: center;
      justify-content: center;
      margin: 32px auto 32px auto;

      @include respond(desktop) {
        margin: 32px 0 0 0;
        display: inline-block;
      }
    }

    &__button {
      text-align: center;
      appearance: none;
      display: inline-block;
      background-color: var(--btn-bg);
      border-radius: var(--border-radius);
      border: none;
      color: var(--btn-color);
      font-size: var(--btn-text);
      font-weight: 500;
      padding: 1.2rem 2.4rem;
      transition: box-shadow 0.3s ease-in;

    }

    &__images {
      flex: 0 0 50%;

      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 48px;
    }

    &__image {
      width: 150px;
      height: 150px;
      margin-bottom: 24px;

      @include respond(tablet-lg) {
        width: 200px;
        height: 200px;
      }

      flex: 0 0 50%;
      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .GallerySection {
    padding: 64px 24px 16px 24px;
    width: 100%;

    &__image {
      width: auto;
      height: 600px;
      margin-right: 0;

      @include respond(tablet-lg) {
        margin-right: 32px;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .active-dot {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: var(--pink);
      margin-right: 16px;
    }

    .inactive-dot {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: var(--white);
      margin-right: 16px;
    }
  }

  .RoadmapSection {
    &__inner {
      max-width: 1200px;
      min-height: 80vh;
      margin: 0 auto;
      padding: 64px 24px;
    }

    &__header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 32px;

   

      & h2 {
        margin-right: 8px;
        margin-bottom: 12px;
        display: inline-block;
      }

      @include respond(tablet-lg) {
        flex-direction: row;
        align-items: center;
        
        & h2 {
          margin-right: 24px;
        }
      }
    }

    &__sub-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__header-box {
      display: inline-block;
      width: 16px;
      height: 16px;
      border-radius: 4px;
      background: var(--purple);
      margin-right: 8px;
    }

    &__content {
      margin-bottom: 32px;
    }

    &__roadmaps {
      & h3 {
        color: var(--black);
        margin-bottom: 32px;
      }
    }

    &__roadmap {
      background: var(--yellow);
      padding: 24px;
      border-radius: 16px;
      &:not(:last-child) {
        margin-bottom: 24px;
      }

      & ul {
        list-style: none;

        & li {
          margin-bottom: 12px;
        }
      }
    }
    &__roadmap-title {
      color: var(--black-heading);
      margin-bottom: 16px;
    }
  }

  .FaqSection {
    width: 100%;

    &__inner {
      max-width: 1200px;
      padding: 0 24px;
      margin: 0 auto;
    }

    &__title {
      text-align: center;
      margin-bottom: 32px;
    }

    &__body {
      font-size: 16px;
      font-weight: 300;
    }
  }

  
}
