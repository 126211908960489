.NotFound {
  width: 100%;
  background: 
    url("../../../public/img/bg-moon.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  &__inner {
    max-width: 1200px;
    min-height: 100vh;
    padding: 64px 24px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__image {
    width: 350px;
    height: auto;
		margin-bottom: 16px;

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  & h2 {
    margin-bottom: 32px;
		text-align: center;
  }

  & p {
    max-width: 600px;
    margin: 0 auto 32px auto;
    text-align: center;
  }

  & button {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
