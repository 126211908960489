.container {
  width: 100%;
  background: url("../../../public/img/coming_soon.jpg");
  background-size: cover;
  background-position: center center;
  /* max-width: 1200px; */
  /* margin: 0 auto; */
  padding: 64px 24px;
  min-height: 85vh;
}

.pink {
  display: flex;
  max-width: 1200px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 169, 194, 0.31);
  /* padding: 8rem; */

  max-width: 1200px;
  margin: 0 auto;
  padding: 64px 24px;
  /* min-height: 90vh; */
}

.top {
  display: flex;
  flex-direction: row;
}
.her {
  margin-right: 6rem;
}

.her img {
  width: 30rem;
}

.mint {
  background-color: rgba(238, 116, 149, 1);
  padding: 4rem 3rem;
  border-radius: 5px;
  color: white;
  text-align: center;
  max-width: 527px;
}

.title {
  font-weight: 600;
  font-size: 20px;
}

.subtitle {
  font-size: 50px;
  font-weight: 800;
}

.input {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.input input {
  border-radius: 5px;
  padding: 2px;
  font-size: 20px;
  text-align: center;
  border: 1px solid black;
  /* width: 261px; */
  height: 74px;
  margin: 0 2rem;
  /* color: gray; */
  font-weight: 700;
  /* font-size: 2px; */
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.sub2 {
  margin: 15px 0;
  font-size: 18px;
  font-weight: 600;
}

.btn {
  background-color: #fff;
  padding: 1rem;
  text-align: center;
  color: #ee7495;
  width: 284px;
  border-radius: 5px;
  font-weight: 600;
  margin: 0 auto;
}
.btn:hover,
.minus:hover,
.plus:hover {
  cursor: pointer;
  animation: slidein 0.4s;
  transform: scale(1.05);
  transition: all 0.2s ease-in-out;
}
.countdown {
  text-align: center;
  font-size: 60px;
  font-weight: 800;
  flex-direction: row;
  display: flex;
  margin: auto;
}

.time {
  flex-direction: column;
  display: flex;
  padding: 0 3px;
}

.time span:nth-child(2) {
  font-size: 15px;
}

@media screen and (max-width: 1440px) {
  .container {
    max-width: 1200px;
  }
}

@media screen and (max-width: 1000px) {
  .container {
    max-width: 100%;
  }

  .her {
    margin: 1rem;
  }
  .her img {
    width: auto;
  }
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 2em;
  }

  .mint {
    padding: 1rem;
  }

  .title {
    font-weight: 600;
    font-size: 15px;
  }

  .subtitle {
    font-size: 40px;
    font-weight: 800;
  }

  .input {
    display: flex;
    flex-direction: row;
  }
  .input input {
    font-size: 15px;
    text-align: center;
    border: 1px solid black;
    width: 180px;
    height: 5rem;
    margin: 0;
  }
  .minus,
  .plus {
    width: 4rem;
  }

  .sub2 {
    margin: 15px 0;
    font-size: 15px;
    font-weight: 600;
  }
  .her img {
    width: 30rem;
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 0 0.5em;
  }
}
