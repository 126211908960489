.Accordion {
	width: 100%;
	background: var(--grey);
	border-radius: 16px;
	cursor: pointer;
	margin-bottom: 16px;

	&__inner {
		padding: 16px 24px;
	}

	&__header-inner {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&__image-open {
		transition: 0.5s;
		transform: rotate(0.5turn);
	}

	&__body {
		transition: all 0.5s;
		padding-top: 16px;
		font-size: 14px;
		font-weight: 300;
	}

	&__title {
		margin-bottom: 0;
	}
}