@mixin respond($breakpoint) {
  @if $breakpoint == tablet-sm {
    @media only screen and (min-width: $breakpoint-tablet-sm) {
      @content;
    }
  }

  @if $breakpoint == tablet-lg {
    @media only screen and (min-width: $breakpoint-tablet-lg) {
      @content;
    }
  }

  @if $breakpoint == navbar-header {
    @media only screen and (min-width: $breakpoint-navbar-header) {
      @content;
    }
  }

  @if $breakpoint == desktop {
    @media only screen and (min-width: $breakpoint-desktop) {
      @content;
    }
  }
}
