.Footer {
  width: 100%;

  &__inner {
    max-width: 1200px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 24px;

		@include respond(tablet-lg) {
			flex-direction: row;
		}
  }

  &__content {
    & img {
      width: auto;
      height: 24px;

      @include respond(tablet-lg) {
        & img {
          width: auto;
          height: 100%;
        }
      }
    }
  }

  &__social-links {
    margin-bottom: 16px;
		
		@include respond(tablet-lg) {
			margin-bottom: 0;
		}

    & a {
      &:not(:last-child) {
        margin-right: 24px;
      }
    }
  }
}
