.Story {
	width: 100%;
	background: url('../../../public/img/coming_soon.jpg');
	background-size: cover;
	background-position: center center;

	&__inner {
		max-width: 1200px;
		margin: 0 auto;
		padding: 64px 24px;
		min-height: 90vh;
	

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	& h1 {
		text-align: center;
	}

	& p {
		max-width: 600px;
		margin: 48px auto;
		text-align: center;
	}

	&__social-links {
		& a {
			&:not(:last-child) {
				margin-right: 24px;
			}
		}
	}
}